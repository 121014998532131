import Cookies from "./cookies"
import { IS_PRODUCTION } from './environment'

type ScarabTuple = [string, string | [string] | object[] | []]

declare global {
  interface Window {
    emarsysData : EmarsysData,
    ScarabQueue : Array<[string] | ScarabTuple>
  }
}

export interface EmarsysData {
  cart?: object[] | []
  category?: string
  purchase?: object[]
  tag?: string
  view?: string
}

const EMARSYS_CART = "emarsysCart"
const TEST_MODE = "testMode"
const cartCookie = (): object[] | [] => JSON.parse(Cookies.get(EMARSYS_CART) || "[]")

export const Emarsys = (data : EmarsysData | null | undefined) : void => {
  data = data || {}

  window.ScarabQueue = []
  const ScarabQueue = window.ScarabQueue

  if (!IS_PRODUCTION) {
    ScarabQueue.push([TEST_MODE])
  }

  const inThirtyMinutes = 1/48
  const cookieValue = data.cart ? data.cart : cartCookie()
  Cookies.set(EMARSYS_CART, JSON.stringify(cookieValue), {
    expires: inThirtyMinutes
  })

  if (data.view) {
    ScarabQueue.push(['view', data.view])
  }
  if (data.category) {
    ScarabQueue.push(['category', data.category])
  }
  if (data.tag) {
    ScarabQueue.push(['tag', data.tag])
  }

  if (data.purchase) {
    Cookies.remove(EMARSYS_CART)
    ScarabQueue.push(['cart', []])
    ScarabQueue.push(['purchase', data.purchase])
  } else {
    ScarabQueue.push(['cart', cartCookie()])
  }

  ScarabQueue.push(['go'])
}
